import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { userResetPasswordRouting } from '@app/authentication/constants/routing.constants';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import {
    ConfirmationErrorResponse,
    ErrorResponse,
    HttpStatusCode,
    HttpUtils,
    ObjectUtils,
    ResetPasswordErrorResponse,
} from '@smooved/core';
import { ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Exception } from '../constants/exceptions.constants';
import { endpointsWithoutNotification, errorsWithoutNotification, noErrorNotificationHeader } from './http-interceptor.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    public isRefreshingToken = false;
    public tokenSubject = new BehaviorSubject<string>(null);

    private readonly translateService = inject(TranslateService);
    private readonly notificationSandbox = inject(NotificationSandbox);
    private readonly modalSandbox = inject(ModalSandbox);
    private readonly authenticationSandbox = inject(AuthenticationSandbox);
    private readonly router = inject(Router);

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.router.url.includes('companion')) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError((errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
                return this.handleError(errorResponse, request, next);
            })
        );
    }

    private handleError(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { error, status } = errorResponse;
        const errorCode = error?.errorCode as string;
        if (errorResponse instanceof HttpErrorResponse) {
            // don't show notification when calling /me endpoint ( APP_INIT )
            // or when error is listed in array for not showing a notification
            if (errorsWithoutNotification.includes(errorCode) || endpointsWithoutNotification.includes(`${errorResponse.url}`)) {
                return throwError(() => errorResponse);
            }

            switch (status) {
                case 0:
                    return this.handleFailedError(error);
                case HttpStatusCode.Unauthorized: {
                    return this.handleUnauthorizedError(errorResponse);
                }
                case HttpStatusCode.Conflict:
                    return this.handleConflictError(error as ConfirmationErrorResponse, request, next);
                default:
                    return this.handleCommonError(error, request);
            }
        } else {
            return throwError(() => errorResponse);
        }
    }

    private handleUnauthorizedError(errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> {
        const errorCode = HttpUtils.getErrorCode(errorResponse);
        switch (errorCode.message) {
            case Exception.LoginExpired:
                return this.handlePasswordResetError(errorResponse as ResetPasswordErrorResponse);
            default:
                return this.handleDefaultUnauthorizedError(errorResponse.error);
        }
    }

    private handleDefaultUnauthorizedError(errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> {
        const errorCode = HttpUtils.getErrorCode(errorResponse);
        this.notificationSandbox.error(errorCode.message || 'ERROR.401');
        return throwError(() => errorResponse);
    }

    private handlePasswordResetError(errorResponse: ResetPasswordErrorResponse): Observable<HttpEvent<any>> {
        const resetToken = errorResponse.error.errorCode.resetToken;

        if (resetToken) {
            this.authenticationSandbox.resetToken = resetToken;
            this.authenticationSandbox.resetData = ObjectUtils.omit(errorResponse.error.errorCode, 'resetToken');

            void this.router.navigate(userResetPasswordRouting);
            return this.authenticationSandbox.resetToken$.pipe(
                filter((token) => !token),
                take(1),
                switchMap(() => throwError(errorResponse))
            );
        }
        this.notificationSandbox.error(errorResponse.error.errorCode.message || 'ERROR.401');
        return throwError(() => errorResponse);
    }

    private handleFailedError(errorResponse: ErrorResponse): Observable<HttpEvent<any>> {
        this.notificationSandbox.warning('ERROR.0');
        return throwError(() => errorResponse);
    }

    private handleConflictError(
        error: ConfirmationErrorResponse,
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const dialogRef = this.modalSandbox.openConfirmModal({
            question: error.errorCode.confirmationMessage,
        });

        return dialogRef.afterClosed().pipe(
            switchMap((confirmed: boolean): Observable<HttpEvent<any>> => {
                if (confirmed) {
                    const headers = { [error.errorCode.confirmationHeader]: 'true' };
                    const confirmationRequest = HttpUtils.setHeaders(request, headers);
                    return next.handle(confirmationRequest);
                }
                return;
            })
        );
    }

    private handleCommonError(errorResponse: ErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        const headers = request.headers;
        if (!headers.get(noErrorNotificationHeader)) {
            const errorObject = ObjectUtils.isObject(this.translateService.instant(errorResponse.errorCode as string));
            if (errorObject) {
                this.notificationSandbox.error(`${errorResponse.errorCode}.LABEL`);
            } else {
                this.notificationSandbox.error((errorResponse.errorCode as string) || errorResponse.error || 'ERROR');
            }
        }
        return throwError(() => errorResponse);
    }
}
