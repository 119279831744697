import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { SendConfirmationLocation } from '@app/move/enums/send-confirmation-location.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { ClosableModalTemplateComponent } from '@smooved/ui';

@Component({
    selector: 'app-send-confirmation-modal',
    template: `
        <app-closable-modal-template>
            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <h3 class="u-margin-bottom">{{ 'ADMIN.SEND_CONFIRMATION.TITLE' | translate }}</h3>

                <app-email-input
                    width="100%"
                    [label]="'ADMIN.SEND_CONFIRMATION.EMAIL.LABEL' | translate"
                    formControlName="email"
                ></app-email-input>

                <ui-dropdown-input
                    width="100%"
                    formControlName="location"
                    [options]="locationOptions"
                    [hasMarginDouble]="true"
                    [hasMargin]="false"
                ></ui-dropdown-input>

                <div class="u-flex-row u-flex-justify-content-center">
                    <app-button type="submit">{{ 'ADMIN.SEND_CONFIRMATION.CTA' | translate }}</app-button>
                </div>
            </form>
        </app-closable-modal-template>
    `,
    styles: [],
})
export class SendConfirmationModalComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public locationOptions: DropdownInput<SendConfirmationLocation>[] = [
        {
            name: 'location',
            value: SendConfirmationLocation.TelecomSimulations,
            label: this.translateService.instant('ADMIN.SEND_CONFIRMATION.LOCATION.OPTION1.LABEL'),
            id: 'location1',
        },
        {
            name: 'location',
            value: SendConfirmationLocation.EnergySimulations,
            label: this.translateService.instant('ADMIN.SEND_CONFIRMATION.LOCATION.OPTION2.LABEL'),
            id: 'location2',
        },
        {
            name: 'location',
            value: SendConfirmationLocation.Dashboard,
            label: this.translateService.instant('ADMIN.SEND_CONFIRMATION.LOCATION.OPTION4.LABEL'),
            id: 'location4',
        },
    ];

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        location: [null, [Validators.required]],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private moveSandbox: MoveSandbox,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.emailOnce$.subscribe((email) => {
            if (email) {
                this.emailFormControl().patchValue(email);
            }
        });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.moveSandbox.moveOnce$.subscribe((move) => {
                this.moveSandbox
                    .sendConfirmation({
                        id: move?._id,
                        email: this.emailFormControl().value,
                        location: this.locationFormControl().value,
                    })
                    .subscribe((_) => {
                        this.closeDialog();
                    });
            });
        }
    }

    private closeDialog(): void {
        this.closableModalComponent.close();
    }

    private emailFormControl(): AbstractControl {
        return this.form?.get('email');
    }

    private locationFormControl(): AbstractControl {
        return this.form?.get('location');
    }
}
